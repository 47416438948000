<template>
<div class="PreviousPage">
  <div class="back">
    <router-link :to="{name: 'Blog'}" class="button full ">Retour à la liste des articles</router-link>
  </div>
</div>
</template>

<script>
export default {
  name: "PreviousPage"
}
</script>

<style lang="scss" type="text/scss" scoped>
.button.full{
  font-size: 14px;
}
.back{
  position: page;
  top: 190px;
  left: 100px;
  width: 400px;
  color: $White;
  z-index: 9;
  background-color: $DarkBrown;
  &:hover {
    background: transparent;
    box-shadow: none;
  }
}
</style>